@layer components {
  .bc-list {
    @apply flex flex-col flex-shrink-0;
  }

  .bc-list:empty {
    display: none;
  }

  .bc-list.bc-list-normal {
    @apply space-y-2;
  }

  .bc-list.bc-list-dense {
    @apply space-y-px overflow-hidden bg-gray-100 shadow-md rounded-t-2xl rounded-b-2xl dark:bg-gray-600;
  }

  .bc-list.bc-list-dense > .bc-list-item {
    @apply rounded-none;
  }

  .bc-list.bc-list-dense.bc-list-active-primary {
    @apply bg-blue-50 dark:bg-blue-500;
  }

  .bc-list.bc-list-dense.bc-list-active-cashback {
    @apply border-2 border-salmon-300 bg-salmon-100 dark:bg-salmon-500;
  }

  .bc-list.bc-list-dense.bc-list-active-primary > .bc-list-item {
    @apply bg-blue-50 dark:bg-blue-500;
  }

  .bc-list.bc-list-dense.bc-list-active-cashback > .bc-list-item {
    @apply bg-salmon-50 dark:bg-salmon-600;
  }

  .bc-list.bc-list-not-interactive {
    @apply shadow-transparent;
  }

  .bc-list.bc-list-not-interactive > .bc-list-item {
    @apply px-0 bg-gray-100 pointer-events-none dark:bg-gray-600;
  }

  .bc-tabs-selected-indicator-shadow {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }

  .bc-toggle-button-loading:before {
    @apply bg-blue-400;
    background-image: linear-gradient(
      45deg,
      #d6e2ff 25%,
      transparent 25%,
      transparent 50%,
      #d6e2ff 50%,
      #d6e2ff 75%,
      transparent 75%,
      transparent
    );
    background-size: 20px 20px;
    animation: bc-toggle-button-loader 1s linear infinite;
  }

  @keyframes bc-toggle-button-loader {
    0% {
      background-position: 20px 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .bc-xss a {
    @apply font-medium text-blue-400 underline pointer-events-auto underline-offset-2 dark:text-gray-0;
  }
  .bc-xss .bc-italic,
  .bc-xss i,
  .bc-xss em {
    @apply italic;
  }
  .bc-xss .bc-underline {
    @apply underline;
  }
  .bc-xss .bc-code {
    @apply p-1 font-mono bg-gray-200 dark:bg-gray-400;
  }
  .bc-xss .bc-bold,
  .bc-xss b,
  .bc-xss strong {
    @apply font-bold;
  }

  .bc-xss iframe {
    pointer-events: auto;
    max-width: 100% !important;
    max-height: 40vh;
  }

  .bc-xss .PlaygroundEditorTheme__embedBlock {
    user-select: none;
  }
  .bc-xss .PlaygroundEditorTheme__embedBlock iframe {
    @apply inline-block;
  }

  .bc-xss .PlaygroundEditorTheme__embedBlockFocus {
    outline: 2px solid rgb(60, 132, 244);
  }

  .bc-xss .bc-h1 {
    @apply text-5xl;
  }

  .bc-xss .bc-h2 {
    @apply text-4xl;
  }

  .bc-xss .bc-h3 {
    @apply text-3xl;
  }

  .bc-xss .bc-h4 {
    @apply text-2xl;
  }

  .bc-xss .bc-h5 {
    @apply text-xl;
  }

  .bc-xss .bc-h6 {
    @apply text-lg;
  }

  .bc-xss .bc-ul {
    @apply pl-8 list-disc;
  }

  .bc-xss .bc-ol {
    @apply pl-8 list-decimal;
  }

  .bc-xss .bc-ul > li {
    @apply mb-4;
  }

  .bc-xss .bc-ul.dense > li {
    @apply mb-0;
  }

  .bc-xss .bc-ul > li:last-child {
    @apply mb-0;
  }

  .bc-xss .bc-icon-list {
    @apply flex;
  }

  .bc-xss .bc-icon-list + .bc-icon-list {
    @apply mt-4;
  }

  .bc-xss .bc-icon-list > svg {
    @apply flex-shrink-0 mr-4;
  }

  .bc-xss .bc-quote {
    @apply p-2 italic bg-gray-200 border-l-4 dark:bg-gray-500 dark:border-l-gray-400 dark:text-gray-0 border-l-gray-300;
  }

  .bc-xss .bc-checklist {
    @apply relative;
  }
  .bc-xss .bc-checklist-unchecked {
    @apply pl-6 list-none before:absolute before:w-5 before:h-5 before:bg-blue-100 dark:before:bg-gray-500 before:block before:left-0 before:top-px;
  }

  .bc-xss .bc-ul:has(.bc-checklist-checked),
  .bc-xss .bc-ul:has(.bc-checklist-unchecked) {
    @apply px-0;
  }
  .bc-xss .bc-checklist-checked {
    @apply pl-6 list-none before:absolute before:w-5 before:h-5 before:bg-blue-400 before:block before:left-0 before:top-px;
  }
  .bc-xss .bc-checklist-checked::after {
    content: '';
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 4px;
    width: 6px;
    left: 7px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
  }

  .bc-xss .editor-image .image-resizer {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
  }

  .bc-xss .editor-image .image-control-wrapper--resizing {
    touch-action: none;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-se {
    bottom: -2px;
    right: -6px;
    cursor: nwse-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-s {
    bottom: -2px;
    left: 48%;
    cursor: s-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-sw {
    bottom: -2px;
    left: -6px;
    cursor: sw-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
  }

  .bc-xss .editor-image .image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
  }

  .bc-xss .editor-image {
    @apply relative inline-block select-none;
  }

  .bc-xss .editor-image img.focused.draggable {
    @apply select-none outline-2 outline-blue-500 cursor-grab outline;
  }

  /* TOAST */
  .bc-toast-container.Toastify__toast-container.Toastify__toast-container--top-center {
    width: calc(100vw - 1rem);
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }

  .bc-toast-container .bc-toast.Toastify__toast {
    @apply gap-2 p-4 m-0 rounded-2xl;
  }

  .bc-toast-container .bc-toast.Toastify__toast--success {
    @apply bg-green-400;
  }

  .bc-toast-container .bc-toast.Toastify__toast--error {
    @apply bg-red-400;
  }

  .bc-toast-container .bc-toast .Toastify__toast-icon {
    @apply w-6 h-6 m-0;
  }

  .bc-toast-container .bc-toast .Toastify__toast-body > div:last-child {
    @apply text-base font-light font-primary;
  }
}

.react-datepicker {
  @apply dark:!bg-gray-500 !rounded-2xl overflow-hidden font-primary dark:border-gray-600 shadow-darkLg;
}

.react-datepicker__header {
  @apply dark:bg-gray-600 dark:border-gray-600;
}

.react-datepicker__day {
  @apply dark:hover:bg-gray-400;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  @apply dark:bg-gray-600;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
  @apply dark:text-gray-0;
}

.react-datepicker__day.react-datepicker__day--selected {
  @apply bg-blue-400;
}

.react-datepicker__navigation {
  @apply !top-2.5;
}

.react-datepicker__navigation-icon {
  @apply !top-1;
}
.react-datepicker__time-container {
  @apply dark:border-gray-600;
}
.react-datepicker-time__header {
  @apply dark:text-gray-0;
}
.react-datepicker__time {
  @apply dark:!bg-gray-500 dark:text-gray-0;
}
.react-datepicker__time-list-item {
  @apply dark:hover:!bg-gray-600;
}
.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
  @apply !bg-blue-400;
}

.react-datepicker__close-icon {
  @apply !right-4 !p-0;
}

.react-datepicker__close-icon::after {
  @apply !bg-transparent !w-6 !h-6 !text-gray-0 bg-close-icon-dark dark:bg-close-icon-light !p-0;
  content: ' ' !important;
}

@keyframes swipe-component-bounce-effect {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(6px);
  }
}

.swipe-bounce-animation {
  animation-name: swipe-component-bounce-effect;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes swipe-component-bounce-background-effect {
  0% {
    background: rgba(214, 226, 255, 0);
  }
  100% {
    background: rgba(214, 226, 255, 0.1);
  }
}

.swipe-bounce-background-animation {
  animation-name: swipe-component-bounce-background-effect;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/ui/src/index.css';

@import 'tailwindcss/utilities';

input[type='datetime-local']::-webkit-inner-spin-button,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
